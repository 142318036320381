<template>
  <div v-on:scroll="handleScroll">
    <v-app>
      <div id="skip">
        <a href="#skip-here">Skip Content</a>
      </div>
      <v-app-bar app color="accent" flat height="80" class="app-bar">
        <router-link to="/" class="z-up" aria-label="Home"
          ><img
            class="mr-3"
            src="/tax_logo.svg"
            alt="Logo Don't nuke the taxonomy"
            height="60"
        /></router-link>

        <v-spacer></v-spacer>
        <div class="hidden-sm-and-down z-up">
          <v-btn
            color="primary"
            class="h-text"
            text
            rounded
            v-for="link in links"
            :key="`${link.label}-header-link`"
            :to="link.url"
            :aria-label="link.label"
            >{{ link.label }}</v-btn
          >
        </div>
        <div class="hidden-md-and-up z-up">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon
                color="primary"
                aria-label="Main menu"
                v-bind="attrs"
                v-on="on"
              ></v-app-bar-nav-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(link, index) in links"
                :key="index"
                :to="link.url"
                color="primary"
              >
                <v-list-item-title>{{ link.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
      <router-view />

      <v-footer color="accent.lighten2" padless absolute>
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="footerLink in footerLinks"
            :key="`${footerLink.label}-footer-link`"
            color="black"
            text
            rounded
            class="my-2 mt-6"
            :to="footerLink.url"
          >
            {{ footerLink.label }}
          </v-btn>
          <v-col class=" py-4 text-center black--text" cols="12">
            {{ new Date().getFullYear() }} –
            <a href="http://www.pulswerk.at/" target="_blank"
              ><strong>pulswerk</strong></a
            >
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";

export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: "de"
      }
    };
  },
  methods: {
    handleScroll() {
      this.offsetTop = window.scrollY;
    }
  },
  components: {
    // NavBar
  },
  computed: {
    color: function() {
      let col = "transparent";
      if (this.offsetTop > 0) {
        col = "white";
      }
      return col;
    }
  },
  data() {
    return {
      offsetTop: 0,
      links: [
        {
          label: "Home",
          url: "/"
        },
        {
          label: "Information",
          url: "/info"
        },
        {
          label: "About",
          url: "/about"
        },
        {
          label: "Contact",
          url: "/contact"
        },
        {
          label: "de",
          url: "/de"
        }
      ],
      footerLinks: [
        {
          label: "Imprint",
          url: "/impressum"
        },
        {
          label: "Privacy",
          url: "/privacy"
        }
      ]
    };
  }
};
</script>
<style>
@import url("https://use.typekit.net/aqk3vbc.css");

#app {
  font-family: "urw-din", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--v-secondary-base);
  margin-top: 80px;
}
#skip a {
  display: block;
  position: absolute;
  left: -999px;
  top: -999px;
}

#skip a:focus {
  left: 0;
  top: 0;
  padding: 3px;
  border: 1px solid var(--v-primary-base);
  z-index: 1000;
}

main {
  margin-bottom: 50px;
}

.h-text,
h1,
h2,
h3,
h4 {
  font-family: "urw-din", sans-serif;
  line-height: 1;
  color: var(--v-secondary-base);
}

h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.sec:nth-child(odd) {
  background-color: #7c7c7c17;
}

.sec:nth-child(even) {
  /* background-color: var(--v-accent-base); */
}

.sec {
  min-height: 30vh;
  padding: 50px;
  width: 100%;
  /* background-color: var(--v-accent-lighten1); */
}

#map {
  width: 100%;
  height: 70vh;
}

@media all and (max-width: 980px) {
  #map {
    height: 50vh;
  }
}

main {
  margin-bottom: 150px;
}

.v-card__title {
  font-size: 1.5em;
}

.credit {
  font-weight: light;
  font-size: 14px;
}
</style>
