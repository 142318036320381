import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "@/views/About.vue";
import Info from "@/views/Info.vue";
import Contact from "@/views/Contact.vue";
import De from "@/views/De.vue";
import Privacy from "@/views/Privacy.vue";
import Imprint from "@/views/Imprint.vue";
import PageNotFound from "@/views/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "Home" }
  },
  {
    path: "/info",
    name: "info",
    component: Info,
    meta: { title: "Information" }
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: { title: "About" }
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: { title: "Contact" }
  },
  {
    path: "/de",
    name: "de",
    component: De,
    meta: { title: "De" }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    meta: { title: "Privacy" }
  },
  {
    path: "/impressum",
    name: "impressum",
    component: Imprint,
    meta: { title: "Imprint" }
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { title: "Page not Found" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " – Don't nuke the taxonomy";
  next();
});

export default router;
