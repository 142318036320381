<template>
  <div>
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0 main"
    >
      <div class="splash">
        <router-link to="/" class="z-up" aria-label="Home"
          ><img class="splash-logo" src="/tax_logo.svg" alt="Logo Icon"
        /></router-link>
      </div>
      <v-col v-if="homeTax.LandingText">
        <TextBlock
          :title="homeTax.LandingText.Title"
          :content="homeTax.LandingText.Content"
          :textBlockImage="homeTax.LandingText.textBlockImage"
        ></TextBlock>
      </v-col>

      <v-container>
        <masonry
          :cols="{ default: 3, 1263: 2, 700: 1 }"
          :gutter="{ default: '20px', 700: '15px' }"
        >
          <div v-for="example in homeTax.News" :key="example.name">
            <Card :example="example" @set-sheet="showSheet" />
          </div>
        </masonry>

        <v-bottom-sheet scrollable v-model="sheet" width="80%" height="80vh">
          <v-sheet scrollable>
            <v-card>
              <v-btn class="mt-6 mx-6 float-right" icon @click="sheet = !sheet">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-title
                ><h2 class="px-2  h-text">{{ title }}</h2></v-card-title
              >
              <v-card-text class="py-3 px-6" style="height: 70vh"
                ><VueShowdown
                  :markdown="description"
                  flavor="github"
                ></VueShowdown
              ></v-card-text>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import Callforaction from "@/components/Callforaction.vue";
import TextBlock from "@/components/TextBlock.vue";
import Card from "@/components/Card.vue";
import { VueShowdown } from "vue-showdown";

export default {
  components: {
    Card,
    VueShowdown,
    TextBlock
  },

  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_API_URL,
    homeTax: [],
    sheet: false,
    title: "",
    description: ""
  }),
  apollo: {
    homeTax: gql`
      query homeTax {
        homeTax {
          LandingText {
            Title
            Content
            textBlockImage {
              formats
              url
              alternativeText
              caption
            }
          }
          News {
            name
            shortDescr
            descr
            image {
              formats
              url
              alternativeText
              caption
            }
          }
        }
      }
    `
  },
  methods: {
    showSheet: function(example) {
      this.sheet = !this.sheet;
      this.title = example.name;
      this.description = example.descr;
    }
  }
};
</script>

<style scoped>
.splash {
  margin-top: -80px;
  background-color: #ffdf00;
  height: 65vh;
  position: relative;
}

.splash-logo {
  width: 80%;
  max-width: 1000px;
  height: calc(60vh - 80px);
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-card__text {
  overflow: auto;
}

@media all and (max-width: 980px) {
  .sec {
    padding: 15px;
  }
}
</style>
