<template>
  <v-row class="d-flex justify-center">
    <v-col v-if="textBlockImage" cols="12" md="4" class="image-container">
      <ResponsiveImage
        :imageObject="textBlockImage"
        sizes="(max-width: 960px) 100vw, (min-width: 960px) 50vw"
      ></ResponsiveImage>

      <p class="credit" v-if="textBlockImage && textBlockImage.caption">
        {{ "&copy; " + textBlockImage.caption }}
      </p>
    </v-col>

    <v-col
      cols="12"
      md="8"
      class="text-button-container d-flex flex-column align-start justify-center"
    >
      <h2>{{ title }}</h2>
      <VueShowdown :markdown="content" flavor="github"></VueShowdown>
    </v-col>
  </v-row>
</template>

<script>
import { VueShowdown } from "vue-showdown";
import ResponsiveImage from "@/components/ResponsiveImage.vue";

export default {
  components: {
    VueShowdown,
    ResponsiveImage
  },
  data: function() {
    return {
      api_url: process.env.VUE_APP_STRAPI_API_URL
    };
  },
  computed: {
    embedLink: function() {
      const match = this.vimeoLink.match(/(vimeo.com\/)(\d+)/);
      const videoId = match[2];

      return "https://player.vimeo.com/video/" + videoId + "?portrait=0";
    }
  },
  props: {
    title: String,
    content: String,
    textBlockImage: Object,
    vimeoLink: String
  },
  methods: {}
};
</script>

<style scoped lang="css">
.text-button-container {
  padding: 10px 10%;
}

.image-container {
  position: relative;
  max-height: 40vh;
  min-height: 40vh;
  padding: 10px 5%;
}

.viedo-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centered-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
