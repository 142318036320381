<template>
  <div class="about">
    <v-main
      id="skip-here"
      class="pt-0 pt-sm-0 pt-xs-0 pt-md-0 pt-lg-0 pt-xl-0 main"
    >
      <v-container>
        <h1>Imprint</h1>
        <VueShowdown
          v-if="impressum.imprintText"
          :markdown="impressum.imprintText"
          flavor="github"
        ></VueShowdown>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { VueShowdown } from "vue-showdown";

export default {
  components: {
    VueShowdown
  },
  data: () => ({
    api_url: process.env.VUE_APP_STRAPI_API_URL,
    impressum: []
  }),
  apollo: {
    impressum: gql`
      query impressum {
        impressum {
          imprintText
        }
      }
    `
  }
};
</script>
