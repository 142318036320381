import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#000",
        secondary: "#2c3e50",
        accent: "#ffdf00",
        error: "#cd1719",
        anchor: "#000"
      }
    }
  }
});

export default vuetify;
